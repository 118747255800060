export default [
  {
    labelHeading: "Dashboard",
    roles: '*',
    children: [
      {
        label: "Home",
        link: "/",
        icon: ["bi", "bi-layers"],
        isExactActive: true,
        roles: '*'
      },
    ],
  },
  {
    label: "Master Data",
    icon: ["bi", "bi-card-list"],
    roles: '*',
    children: [
      {
        label: "Jabatan",
        link: "/jabatan",
        icon: ["bi", "bi-person-badge"],
        roles: '*',
      },
      {
        label: "Jenis Karyawan",
        link: "/jenis-karyawan",
        icon: ["bi", "bi-person-lines-fill"],
        roles: '*',
      },
      {
        label: "Jenis Shift",
        link: "/jenis-shift",
        icon: ["bi", "bi-clock-history"],
        roles: '*',
      },
      {
        label: "Shift",
        link: "/shift",
        icon: ["bi", "bi-clock"],
        roles: '*',
      },
      {
        label: "Jenis Izin",
        link: "/jenis-izin",
        icon: ["bi", "bi-file-earmark-medical"],
        roles: '*',
      },
    ],
  },
  {
    label: "Verfikasi User",
    icon: ["bi", "bi-person-check"],
    roles: '*',
    link: "/verifikasi-user",
  },
  {
    label: "Karyawan",
    icon: ["bi", "bi-person"],
    roles: '*',
    link: "/karyawan",
  },
  {
    label: "Jadwal Kerja",
    icon: ["bi", "bi-calendar-range"],
    roles: '*',
    link: "/jadwal",
  },
  {
    label: "Presensi",
    icon: ["bi", "bi-calendar2-check"],
    roles: '*',
    link: "/presensi",
  },
  {
    label: "Official Duty",
    icon: ["bi", "bi-clipboard"],
    roles: '*',
    link: "/official-duty",
  },
  {
    label: "Tukar Shift",
    icon: ["bi", "bi-arrow-repeat"],
    roles: '*',
    link: "/tukar-shift",
  },
  {
    label: "Izin",
    icon: ["bi", "bi-file-earmark-medical"],
    roles: '*',
    link: "/izin",
  },
  {
    label: "Overtime",
    icon: ["bi", "bi-clock-history"],
    roles: '*',
    link: "/overtime",
  },
  {
    label: "Meal",
    icon: ["bi", "bi-basket2"],
    roles: '*',
    link: "/meal",
  },
  {
    label: "Export",
    icon: ["bi", "bi-file-earmark-arrow-down"],
    roles: '*',
    link: "/export",
  },
  {
    labelHeading: "Setting",
    roles: "*",
    children: [
      {
        label: "Lokasi",
        link: "/lokasi",
        icon: ["bi", "bi-geo-alt"],
        roles: '*',
      },
      {
        label: "Lokasi Karyawan",
        link: "/lokasi-karyawan",
        icon: ["bi", "bi-geo"],
        roles: '*',
      },
      {
        label: "Setting Tahun",
        link: "/setting-tahun",
        icon: ["bi", "bi-calendar"],
        roles: '*',
      },
      {
        label: "Setting Keterlambatan",
        link: "/setting-keterlambatan",
        icon: ["bi", "bi-clock-history"],
        roles: '*',
      },
      {
        label: "Setting Lembur",
        link: "/setting-lembur",
        icon: ["bi", "bi-hourglass-split"],
        roles: '*',
      },
      {
        label: "Overtime Rates",
        link: "/overtime-rate",
        icon: ["bi", "bi-cash-coin"],
        roles: '*',
      },
      {
        label: "Account",
        link: "/my-account",
        icon: ["bi", "bi-person"],
        roles: '*',
      }
    ],
  },
  {
    labelHeading: "Administrator",
    roles: ['1', '2'],
    children: [
      // {
      //   label: "Log Activity",
      //   link: "/log-activity",
      //   icon: ["bi", "bi-clock-history"],
      //   roles: '*'
      // },
      {
        label: "Role",
        link: "/role",
        icon: ["bi", "bi-person-badge"],
        roles: '*'
      },
      // {
      //   label: "Report",
      //   link: "/report",
      //   icon: ["bi", "bi-file-earmark-bar-graph"],
      //   roles: '*'
      // },
      // {
      //   label: "Lembur",
      //   icon: ["bi", "bi-hourglass-split"],
      //   roles: '*',
      //   link: "/lembur",
      // },
    ],
  },
];
