import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

export default {
  baseUrlApi: process.env.NODE_ENV === "production"
    // ? "https://absensi.belanj.id/api"
    ? "https://api.bkmpaiton.com/api"
    // : "https://absensi.belanj.id/api",
    // : "https://api.bkmpaiton.com/api",
    : "http://localhost:8000/api",

  baseUrlImage: process.env.NODE_ENV === "production"
    // ? "https://absensi.belanj.id/public/storage/"
    ? "https://api.bkmpaiton.com/api/file?url="
    // : "https://api.bkmpaiton.com/api/file?url=",
    // : "http://localhost:8000/storage/",
    : "https://api.bkmpaiton.com/api/file?url=",

  chartOptions: {
    donut: {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
    range: {
      chart: {
        height: 450,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
        },
      },
      xaxis: {
        type: "datetime",
      },
      stroke: {
        width: 1,
      },
      fill: {
        type: "solid",
        opacity: 0.6,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    },
  },

  timeConfig: {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
  },

  dateConfig: {
    altInput: true,
    altFormat: "j F Y",
    dateFormat: "Y-m-d",
  },

  dateTimeConfig: {
    altInput: true,
    enableTime: true,
    altFormat: "j F Y H:i",
    dateFormat: "Y-m-d H:i",
  },

  dateEndConfig: {
    altInput: true,
    altFormat: "j F Y",
    dateFormat: "Y-m-d",
    minDate: null,
  },

  dateTimeEndConfig: {
    altInput: true,
    enableTime: true,
    altFormat: "j F Y H:i",
    dateFormat: "Y-m-d H:i",
    minDate: null,
  },

  monthConfig: {
    plugins: [
      new monthSelectPlugin({
        shorthand: true,
        altFormat: "F Y",
        dateFormat: "Y-m",
        theme: localStorage.getItem("theme"),
      })
    ]
  },

  rangeConfig: {
    mode: 'range',
    altInput: true,
    altFormat: "j F Y",
    dateFormat: "Y-m-d",
  },

  theme: localStorage.getItem("theme"),

};
