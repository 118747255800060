import Vue from 'vue'
import axios from "./plugins/axios-vue";
import "./plugins";
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import store from "./store";
import config from "./config";
import titleMixin from "./mixins/title";
import TopProgressBar from "@/components/TopProgressBar.vue";
import OverlayLoading from "@/components/OverlayLoading.vue";
import DashLayout from "@/layouts/Dashboard.vue";
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import fPickr from 'flatpickr'
import flatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts'
import FsLightbox from "fslightbox-vue";
import { Indonesian } from "flatpickr/dist/l10n/id"
// import Pusher from "pusher-js";

// const pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
//   cluster: process.env.VUE_APP_PUSHER_CLUSTER,
//   encrypted: true,
//   useTLS: true,
//   authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
//   auth: {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       Accept: "application/json",
//     },
//   },
// });

// Vue.prototype.$pusher = pusher;

const isProduction = process.env.NODE_ENV === "production";
const theme = localStorage.getItem("theme");
const bar = (
  Vue.prototype.$topprogressbar = new Vue(TopProgressBar).$mount()
);
Vue.prototype.$overlayloading = new Vue(OverlayLoading).$mount();
// Vue.prototype.$channel = channel;

// Flatpickr Locale
fPickr.setDefaults({
  locale: Indonesian,
});

// Plugins
import 'bootstrap-icons/font/bootstrap-icons.css'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Styles
import "@/assets/css/style.bundle.css";
import "@/assets/css/custom.css";
import "@/assets/css/dark.css";
import "@/assets/css/plugins.bundle.css";
import 'vue-select/dist/vue-select.css';
import 'flatpickr/dist/plugins/monthSelect/style.css'

if (theme === "dark") {
  import('flatpickr/dist/themes/dark.css')
    .then(() => {
      document.body.setAttribute("data-theme", "dark");
    })
} else if (theme === "light") {
  import('flatpickr/dist/flatpickr.css')
    .then(() => {
      document.body.setAttribute("data-theme", "light");
    })
} else {
  localStorage.setItem("theme", "light");
  import('flatpickr/dist/flatpickr.css')
    .then(() => {
      document.body.setAttribute("data-theme", "light");
    })
}

// Components
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)
Vue.component('multi-select', Multiselect)
Vue.component('v-select', vSelect)
Vue.component('flat-pickr', flatPickr)
Vue.component('fs-lightbox', FsLightbox)

// Vue Config
Vue.config.performance = true;
Vue.config.silent = isProduction;
Vue.config.productionTip = false;

// Progress Bar
document.body.appendChild(bar.$el);

// Global Layout
Vue.component("dash-layout", DashLayout);

/* GLOBAL APP OBJECT */
Vue.prototype.$App = Object.freeze({
  hasTouchSupport: "ontouchstart" in document.documentElement,
  hasMultiTouchSupport: navigator.maxTouchPoints > 1,
  config,
});

// Global Mixins
Vue.mixin(titleMixin);

new Vue({
  axios,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
